import React, { useState } from "react";
import { Button, FormGroup, FormControl, FormCheck } from "react-bootstrap";
import "./Login.css";
import { HOST } from '../../common/constant';
import Logo from '../../common/Logo/Logo';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { decryptData, encryptData, toastOptions } from "../../common/Utils";
import * as uuid from 'device-uuid';

export default function Login() {
    const [loginId, setLoginId] = useState("");
    const [password, setPassword] = useState("");
    const [keepLogin, setKeepLogin] = useState(true);
    const [hasInput, setHasInput] = useState(false);
    const history = useHistory();

    function validateForm() {
        if (loginId.length <= 0) {
            toast.error("ユーザー名を入力してください。", toastOptions);
            return false;
        }
        if (password.length <= 0) {
            toast.error("パスワードを入力してください。", toastOptions);
            return false;
        }
        return loginId.length > 0 && password.length > 0;
    }

    async function handleSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();
        const printableString = /^[ -~]*$/;
        if (!printableString.test(loginId)
            || !printableString.test(password)) {
            toast.error("指定された形式で入力してください。", toastOptions);
            return;
		}
        if (!validateForm()) {
            return;
        }
        var body = {
            LoginId: loginId,
            Password: password,
            KeepLogin: keepLogin,
            uuid: new uuid.DeviceUUID().get()
        };
        if (keepLogin) {
            await saveLogin();
        } else {
            Cookies.remove('p');
            Cookies.remove('u');
        }
        setPassword('');
        axiosInstance
            .post(HOST() + "/Form/V1/Authenticate", body)
            .then(res => {
                if (res.data.data && res.data.data.token) {
                    let user = res.data.data;
                    Cookies.set('jwt', "bearer " + user.token, { expires: 365 });
                    Cookies.set('user', user, { expires: 365 });
                    const urlParams = new URLSearchParams(
                      window.location.search
                    );
                    const lat = urlParams.get("lat");
                    const lng = urlParams.get("lng");
                    let redirectUrl = "/"
                    if (lat && lng) {
                        redirectUrl = `/?lat=${lat}&lng=${lng}`;
                    }
                    user.roleID === -1
                      ? history.replace("/admin")
                      : history.push(redirectUrl);

                } else if (res.data.message == "TimeNotValid") {
                    toast.error("有効期限を過ぎています。", toastOptions);
                } else if (res.data.message == "LoginByOthers") {
                    toast.error("そのID,passwordはログイン中のため使用できません。", toastOptions);
                } else {
                    toast.error("ユーザーIDとパスワードを確認してください。", toastOptions);
                }

            }).catch(e => {
                toast.error("ユーザーIDとパスワードを確認してください。", toastOptions);
            });

    }

    async function saveLogin() {
        let cipherText: any = await encryptData(password); 
        Cookies.set('p', cipherText, { expires: 365 });
        cipherText = await encryptData(loginId); 
        Cookies.set('u', cipherText, { expires: 365 });
    }

    async function getSavedLogin() {
        const p: any = Cookies.getJSON('p');
        const u: any = Cookies.getJSON('u');
        if (p && u && !hasInput) {
            setHasInput(true);
            setPassword(await decryptData(p));
            setLoginId(await decryptData(u));
            setKeepLogin(true);
        }
    }

    getSavedLogin();
    return (
        <div className="Login">
            <Logo className="logo" />
            <form className="form-login" onSubmit={handleSubmit}>
                <h1>ログイン</h1>
                <FormGroup controlId="email">
                    <FormControl
                        autoFocus
                        type="text"
                        placeholder="ユーザー名"
                        value={loginId}
                        onChange={e => setLoginId(e.target.value)}
                    />
                </FormGroup>
                <FormGroup controlId="password">
                    <FormControl
                        value={password}
                        placeholder="パスワード"
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                    />
                </FormGroup>
                <FormGroup>
                    <FormCheck
                        label="次回ログイン省略"
                        checked={keepLogin}
                        onChange={(e: any) => setKeepLogin(e.target.checked)}
                    />
                </FormGroup>
                <Button block type="submit">
                    ログイン
                </Button>
            </form>
            <br></br>
            {/*<form className="form-meinte">*/}
            {/*    <h1><b>サーバーメンテナンスのお知らせ</b></h1>*/}
            {/*    <h2> 弊社サーバーの強化に伴い、下記のスケジュールにおいてサーバーメンテナンスを実施いたします。</h2>*/}
            {/*    <h2>メンテナンス期間中はぴたあっとWebサイト、ぴたあっとアプリはご利用頂けません。</h2>*/}
            {/*    <h2>ご不便をお掛けいたしますが、ご了承の程、よろしくお願い申し上げます。</h2>*/}
            {/*    <h2><b>■実施日時</b></h2>*/}
            {/*    <h2>2021年11月14日（日）09：00 ～ 2021年11月14日（日）18：00</h2>*/}
            {/*</form>*/}
            <ToastContainer />
        </div>
    );
}
