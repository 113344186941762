import * as React from 'react';
import './AsideLeft.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEllipsisV, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Button } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { getImageFromKey, getDefault as getDefaultIcon } from '../Terminal/EditTerminalIcon'
import axiosInstance from '../../common/interceptor';
import { HOST } from '../../common/constant';
import Cookies from 'js-cookie';
import moment from 'moment';
import { toast } from 'react-toastify';
import { toastOptions, tableResizable, drawPathToMap } from '../../common/Utils';
import eventBus from "../../common/EventBus"

export default class AsideRight extends React.Component<{
    map: any,
    onNavi: Function,
    updateStateFunc: Function,
}, {
    isTruckIcon: boolean,
    isOpen: boolean,
    freeSizeOpen: boolean,
    terminalData: any[],
    commentShow: boolean,
    hasEmergency: boolean,
    isCollapse: boolean
    seconds: number,
    isAdmin: boolean,
    ValidRange: number,
    pitaVersion: string
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            isTruckIcon: false,
            isOpen: false,
            freeSizeOpen: false,
            terminalData: [],
            commentShow: false,
            hasEmergency: false,
            isCollapse: false,
            seconds: 60,
            isAdmin: false,
            ValidRange: 0,
            pitaVersion: '0'
        };

        this.onDropdownSelected = this.onDropdownSelected.bind(this);
        this.onCheckboxChanged = this.onCheckboxChanged.bind(this);
        this.onEditDeleteItem = this.onEditDeleteItem.bind(this);
        this.onAddItem = this.onAddItem.bind(this);
        this.onAddMultiItem = this.onAddMultiItem.bind(this);
        this.onEmergencyMessage = this.onEmergencyMessage.bind(this);
        this.formatDateToMMDDhhmm = this.formatDateToMMDDhhmm.bind(this);
        // Timer to check emegency
        this.timer = 0;
        this.emergencyList = [];
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    timer: any;
    emergencyList: any[];
    gmarkers: any = {};
    comments: any = {};
    commentPos: any = {};
    trajectory: any = {};
    user: any = {};
    componentDidMount() {
        const userData = Cookies.get('user');
        if (userData) {
            this.user = JSON.parse(userData ? userData : '{}');

            this.setState({ pitaVersion: this.user.pitaVersion });
        }
        if (!this.props.map) {
            return;
        }


    }
    componentWillUnmount() {
        eventBus.remove("changeDetailSetting", null);
    }

    private checkPitaVersion(pitaVersion: string) {
        if (pitaVersion) {
            this.setState({
                pitaVersion: pitaVersion
            });
        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (!nextProps.map) {
            return;
        }
        if (this.state.terminalData.length == 0) {
            this.fullFetch();
        }
        eventBus.on("changeDetailSetting", (data: any) =>
            this.checkPitaVersion(data.pitaVersion)
        );
    }

    fullFetch() {
        var api = "/Form/V1/Dat/GPS/GetSetting?siteID=" + this.user.siteId + "&sysID=" + this.user.siteId;
        axiosInstance
            .get(HOST() + api)
            .then(res => {
                this.setState({ ValidRange: Number(res.data.data.validRange) }, () => {
                    this.fetchData();
                });
            });
    }


    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000); // 30s
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
            this.timer = 0;
            // to fetch terminal status
            this.fetchData();
            this.setState({ seconds: 6000 });
        }
    }

    public async fetchData() {
        var user: any = JSON.parse(Cookies.get('user') || "{}");
        this.setState({ isAdmin: user.roleID === 1 })
        await axiosInstance.get(HOST() + "/Form/V1/Mst/User/Info?userID=" + user.id).then((res) => {
            if (res && res.data && res.data.data) {
                this.setState({ isTruckIcon: res.data.data.isShowTruckIcon });
            }
        });
        axiosInstance
            .get(HOST() + `/Form/V1/Dat/Terminal/Status?sysID=${user.siteId}`)
            .then(response => {
                const data = response.data.data;
                const now: any = new Date();
                const terminalData = data.map((e: any) => this.terminalDataMap(e, now));
                this.afterFetchDataDone(terminalData);
            })
            .catch(error => {
                this.startTimer();
            });

    }

    terminalDataMap(e: any, now: any) {
        const LatLgn = e.icon.split(';');
        const timeArrive: any = e.timeArrive == null ? '' : e.timeArrive;
        let timeArriveRemain: any = new Date(timeArrive);
        timeArriveRemain -= now;

        timeArriveRemain = timeArriveRemain <= 0 ? '間もなく到着します' : Math.ceil(timeArriveRemain / 60000) + '分';

        return {
            cliID: e.cliID,
            status: e.status,
            cliNm: e.cliNm,
            driverNm: e.driverNm,
            chargedPercent: e.chargedPercent,
            chargeConnect: e.chargeConnect,
            lat: Number(LatLgn[0] || 0),
            lng: Number(LatLgn[1] || 0),
            startTime: e.startTime,
            endTime: e.endTime,
            updDT: moment(e.updDT) < moment(new Date).add(-20, 'years') ? '' : e.updDT,
            emergency: e.emergency,
            urgentFlg: !(e.emergency.length == 0 || e.emergency === "0.0/0.0/0.0"),
            overSpeed: e.overSpeed,
            wrongRoute: e.wrongRoute,
            display: e.display,
            timeArrive,
            timeArriveRemain,
            arrivalStatusText: e.arrivalStatusText,
            departureStatusText: e.departureStatusText,
            direction: e.direction,
            imei: e.imei,
            posSpd: e.posSpd,
            maxIcon1: e.maxIcon1,
            maxIcon2: e.maxIcon2,
            maxIcon3: e.maxIcon3,
            minIcon1: e.minIcon1,
            minIcon2: e.minIcon2,
            minIcon3: e.minIcon3,
            warningTime: e.warningTime,
            concreteTimeLimit: e.concreteTimeLimit,
            pumpingTime: e.pumpingTime,
            operCount: e.operCount,
            isArrived: e.isArrived,
            pausDT: e.pausDT,
            routeID: e.routeID,
            //posHed: e.posHed
        }
    }
    afterFetchDataDone(terminalData: any) {
        this.setState({ terminalData }, () => {
            this.state.terminalData.forEach((e: any, index: any) => {
                if (e.display) {
                    this.displayTerminalIconsOnMap(index);
                }
            });
            tableResizable('terminalTable');
        });
        this.onEmergencyMessage();
        this.startTimer();
        if (this.state.commentShow) {
            this.showComment();
        }
    }

    indexOfEmergencies(entry: any) {
        const length = this.emergencyList.length;
        for (var i = 0; i < length; i++) {
            if (entry.cliID === this.emergencyList[i].cliID) {
                return i;
            }
        }
        return -1;
    }

    public updatePosition(data: any) {
        if (!this.props.map) { return; }
        const now: any = new Date();
        data = data.map((e: any) => this.terminalDataMap(e, now));
        this.afterFetchDataDone(data);
    }

    public onEmergencyMessage() {
        // this.setState({ terminalData: list });
        var list = this.state.terminalData;
        var latest = [];
        var ids = [];
        for (let entry of list) {
            // "emergency": "0.0/0.0/0.0", cliID, cliNm
            const index = this.indexOfEmergencies(entry);
            // if(entry.emergency === "0.0/0.0/0.0") {
            if (entry.urgentFlg) {
                if (index < 0) {
                    this.emergencyList.push(entry);
                    latest.push(entry.cliNm);
                    ids.push(entry.cliID);
                }
            }
            else {
                if (index >= 0) {
                    this.emergencyList.splice(index, 1);
                }
            }
        }

        if (latest.length > 0) {
            this.props.updateStateFunc({ onUrgentMsgComming: { cliNms: latest, cliIDs: ids } });
        }
    }

    private onCheckboxChanged(item: any, index: number, event: any) {
        item.display = event.target.checked;

        var list = this.state.terminalData;
        list[index] = item;

        this.setState({ terminalData: list });
        this.updateDisplayDb(item.cliID, item.display);
        item.display ? this.displayTerminalIconsOnMap(index) : this.clearMarker(item.cliID);
    }

    private updateDisplayDb(cliID: any, value: any) {
        const body = { CliID: cliID, Use: value ? 1 : 0 };
        axiosInstance.put(HOST() + "/Form/V1/Dat/Terminal/UpdateDisplay", body).then((res) => {
            if (res && res.data && res.data.result) {
                toast.success(value ? '表示になりました。' : '非表示になりました。', toastOptions);
            }
        });
    }

    private displayAll(checked: boolean) {
        const list = this.state.terminalData.map((item) => {
            item.display = checked;
            return item;
        });
        checked ? this.displayTerminalIconsOnMap() : this.clearMarker();

        const body = { CliID: -1, Use: checked ? 1 : 0 };
        axiosInstance.put(HOST() + "/Form/V1/Dat/Terminal/UpdateDisplay", body).then((res) => {
            if (res && res.data && res.data.result) {
                toast.success(checked ? '端末全て表示になりました。' : '端末全て非表示になりました。', toastOptions);
                this.setState({ terminalData: list });
            }
        });
    }

    private displayTerminalIconsOnMap(index: any = -1) {
        if (index < 0 && this.state.terminalData.length == 0) {
            return;
        }
        const isTruckIcon = this.state.isTruckIcon;
        let dataMap: any[] = index < 0 ? this.state.terminalData : [this.state.terminalData[index]];
        var zoom = this.props.map.zoom;
        let ValidRange = this.state.ValidRange;

        dataMap.forEach((point: any) => {
            const lastUpdate: any = new Date(point.updDT);
            if ((-lastUpdate + Date.now()) / 1000 / 3600 > ValidRange) {
                if (this.gmarkers[point.cliID]) {
                    this.gmarkers[point.cliID].setMap(null);
                    delete this.gmarkers[point.cliID];
                    if (this.comments[point.cliID]) {
                        this.comments[point.cliID].close();
                        this.comments[point.cliID].setMap(null);
                        delete this.comments[point.cliID];
                    }
                }
                return;
            }
            const stateColor = this.stateTrips(point);
            const color1 = point.minIcon1 || getDefaultIcon('MinIcon1');
            const color2 = point.minIcon2 || getDefaultIcon('MinIcon2');
            const color3 = point.minIcon3 || getDefaultIcon('MinIcon3');

            const dotColor = stateColor == 'gray' ? color1 : (stateColor == 'red' ? color2 : color3)
            const dotIcon: any = {
                path: 'M 125,5 155,90 245,90 175,145 200,230 125,180 50,230 75,145 5,90 95,90 z',
                fillColor: dotColor,
                fillOpacity: 0.8,
                scale: 0.001 * zoom,
                strokeColor: dotColor,
                strokeWeight: 10,
                labelOrigin: { x: 0, y: 50 },
            };

            const truck0 = getImageFromKey(point.maxIcon1 || getDefaultIcon('MaxIcon1')).props.src;
            const truck1 = getImageFromKey(point.maxIcon2 || getDefaultIcon('MaxIcon2')).props.src;
            const truck2 = getImageFromKey(point.maxIcon3 || getDefaultIcon('MaxIcon3')).props.src;
            let truck: any = stateColor == "gray" ? truck0 : (stateColor == "red" ? truck1 : truck2);
            const oldMarker = this.gmarkers[point.cliID];
            if (oldMarker
                && oldMarker.position.lat() == point.lat
                && oldMarker.position.lng() == point.lng) {
                if (isTruckIcon && oldMarker.icon && oldMarker.icon.url == truck) return;
                if (!isTruckIcon && oldMarker.icon && oldMarker.icon.fillColor == dotIcon.fillColor) return;
            }

            var cliNm = point.cliNm.length > 9 ? point.cliNm.substr(0, 9) : point.cliNm;
            var markerIcon = isTruckIcon
                // ? truck
                ? {
                    url: truck,
                    labelOrigin: { x: 10, y: 5 },
                }
                : dotIcon;

            var marker = new window.google.maps.Marker({
                position: { lat: point.lat, lng: point.lng },
                icon: markerIcon,
                title: point.cliNm,
                map: this.props.map
            });
            if (this.gmarkers[point.cliID]) {
                this.gmarkers[point.cliID].setMap(null);
            }
            this.gmarkers[point.cliID] = marker;

            marker.addListener("click", () => this.props.map.setCenter(marker.getPosition()));
        });
    }

    public mapFocusTo(point: any) {
        if (!point.display) {
            return;
        }
        const lastUpdate: any = new Date(point.updDT);
        if (!point.updDT || (-lastUpdate + Date.now()) / 1000 / 3600 > this.state.ValidRange) {
            return;
        }
        this.props.map.setCenter({ lat: point.lat, lng: point.lng })

        if (this.trajectory[point.cliID]) this.trajectory[point.cliID].setMap(null);
        const fromDate = moment(new Date()).add(-5, 'minutes').format('yyyy-MM-DD HH:mm:ss')
        const toDate = moment(new Date()).format('yyyy-MM-DD HH:mm:ss')
        axiosInstance
            .get(HOST() + `/Form/V1/Dat/Report/TerminalGPS?fromDate=${fromDate}&toDate=${toDate}&CliID=${point.cliID}`)
            .then(response => this.drawRoute(response.data.data, point.cliID));
    }

    private drawRoute(path: any[], cliID: any) {
        if (!path || path.length == 0) {
            return;
        }
        const drawedData = drawPathToMap(path, this.props.map, '3/169/244', false, { hideMarker: true });
        if (this.trajectory[cliID]) this.trajectory[cliID].setMap(null);
        this.trajectory[cliID] = drawedData.drawedPath.polyline;
    }

    private clearMarker(index: number = -1) {
        if (index >= 0) {
            this.gmarkers[index] && this.gmarkers[index].setMap(null);
            delete this.gmarkers[index];
            return;
        }

        Object.keys(this.gmarkers).forEach((m: any) => this.gmarkers[m] && this.gmarkers[m].setMap(null));
        this.gmarkers = {};
    }

    private clearComment() {
        Object.keys(this.comments).forEach((m: any) => this.comments[m] && this.comments[m].close());
        this.comments = {};
        this.commentPos = {};
    }

    private toggleDisplayComments(show: boolean) {
        this.setState({ commentShow: show });
        if (!show) {
            this.clearComment();
            return;
        }

        this.showComment(true);
    }

    private showComment(clear = false) {
        const dataMap: any[] = this.state.terminalData;

        const currentDate: any = new Date();
        const isTruckIcon = this.state.isTruckIcon;
        dataMap.forEach((point: any) => {
            if (clear) {
                localStorage.removeItem('close' + point.cliID);
            }

            if (localStorage.getItem('close' + point.cliID)) return;
            if (!this.gmarkers[point.cliID]) return;

            const stateColor = this.stateTrips(point);
            const color1 = point.minIcon1 || getDefaultIcon('MinIcon1');
            const color2 = point.minIcon2 || getDefaultIcon('MinIcon2');
            const color3 = point.minIcon3 || getDefaultIcon('MinIcon3');

            const dotColor = stateColor == 'gray' ? color1 : (stateColor == 'red' ? color2 : color3)
            const dotIcon: any = {
                fillColor: dotColor,
            };

            const truck0 = getImageFromKey(point.maxIcon1 || getDefaultIcon('MaxIcon1')).props.src;
            const truck1 = getImageFromKey(point.maxIcon2 || getDefaultIcon('MaxIcon2')).props.src;
            const truck2 = getImageFromKey(point.maxIcon3 || getDefaultIcon('MaxIcon3')).props.src;
            let truck: any = stateColor == "gray" ? truck0 : (stateColor == "red" ? truck1 : truck2);

            const oldMarker = this.commentPos[point.cliID];
            if (oldMarker
                && oldMarker.position.lat() == point.lat
                && oldMarker.position.lng() == point.lng
                && oldMarker.cliNm == point.cliNm
                && oldMarker.updDT == point.updDT) {
                if (isTruckIcon && oldMarker.icon && oldMarker.icon.url == truck) return;
                if (!isTruckIcon && oldMarker.icon && oldMarker.icon.fillColor == dotIcon.fillColor) return;
            }
            if (this.comments[point.cliID]) this.comments[point.cliID].close();

            let textColor = '#91b19f';
            if (point.overSpeed > 0) {
                textColor = 'red';
            }
            if (point.wrongRoute) {
                textColor = 'yellow';
            }
            if (point.arrivalStatusText == '終了') {
                textColor = '#91b19f';
            }
            const lastUpdate: any = new Date(point.updDT);
            if ((currentDate - lastUpdate) > 86400000) {
                textColor = '#000000';
            }

            const infowindow = new window.google.maps.InfoWindow();
            const infowindowHeader: any = document.createElement('div');
            const infowindowContent: any = document.createElement('div');

            infowindowHeader.innerHTML =
                `<div style="color:${textColor}">
				    <span>${point.cliNm}</span>
    			 </div>`;

            infowindowContent.innerHTML =
                `<div id="infowindow-content-${point.cliID}" style="color:${textColor}">
                    <span id="place-address"></span><br/>
                    <span id="place-speed"></span>
                </div>`;

            infowindow.setHeaderContent(infowindowHeader);
            infowindow.setContent(infowindowContent);
            google.maps.event.addListener(infowindow, 'closeclick', function () {
                localStorage.setItem('close' + point.cliID, '1');
            });
            //infowindowContent.children[0].children["place-name"].textContent = point.cliNm;
            infowindowContent.children[0].children["place-address"].textContent = moment(point.updDT).format('MM-DD HH:mm');
            infowindowContent.children[0].children["place-speed"].textContent = point.posSpd >= 0 ? (point.posSpd + "km/h") : "";
            infowindow.setOptions({ disableAutoPan: true });
            infowindow.open({
                map: this.props.map,
                anchor: this.gmarkers[point.cliID],
                shouldFocus: false
            });
            this.comments[point.cliID] = infowindow;
            this.commentPos[point.cliID] = {
                position: this.gmarkers[point.cliID].position,
                url: this.gmarkers[point.cliID].icon.url,
                cliNm: point.cliNm,
                updDT: point.updDT
            };
        });
    }

    private onDropdownSelected(index: number, option: any = {}) {
        if (index === 3) this.displayAll(option);
        else if (index === 2) this.toggleDisplayComments(option);
        else {
            let user: any = JSON.parse(Cookies.get('user') || "{}");
            let body: any = {
                UserID: user.id,
                IsShowTruckIcon: !this.state.isTruckIcon
            };
            axiosInstance.post(HOST() + "/Form/V1/Mst/User/UpdateIsShowTruckIcon", body).then((res) => {
                if (res && res.data && res.data.result) {
                    this.setState({ isTruckIcon: !this.state.isTruckIcon }, () => {
                        this.displayTerminalIconsOnMap();
                        if (this.state.commentShow) this.showComment();
                    });
                }
            });
        }
    }

    private onEditDeleteItem(item: any, index: number) {
        const naviData = item;
        this.props.onNavi('/EditTerminalItem', naviData);
    }

    private onAddItem() {
        this.props.onNavi('/EditTerminalItem', null);
    }

    private onAddMultiItem() {
        this.props.onNavi('/EditTerminalMultiItem', { sysID: this.user.siteId });
    }

    public setOpen(isOpen: boolean, freeSizeOpen = false) {
        this.setState({ isOpen, freeSizeOpen });
        const navLeft: any = document.getElementById('navLeft');
        navLeft.style.width = '';
    }

    private getOpenClass() {
        if (this.state.isOpen) {
            return this.state.freeSizeOpen ? 'full-width' : 'show';
        } else {
            return 'hide';
        }
    }

    private stateTrips(item: any) {
        if (!item.imei || item.arrivalStatusText == '終了') {
            return 'gray';
        }

        if (item.direction == 1) {
            return 'green';
        } else if (item.direction == 2) {
            return 'red';
        }

        return "gray";
    }

    //private statePosHed(item: any) {

    //    switch (item.posHed) {
    //        case 0:
    //            return 'gray';

    //        case 1:
    //            return 'red';

    //        case 2:
    //            return 'green';

    //        default:
    //            return 'gray';
    //    }
    //}

    private terminalStatus(item: any) {
        //  "overSpeed": 0.000, "wrongRoute": 0,
        let color = 'bgTerminalNormal';
        if (item.arrivalStatusText == '終了') {
            return color;
        }

        if (item.wrongRoute != 0) color = 'bgWrongRoute';
        if (item.overSpeed > 0) color = 'bgOverSpeed';

        return color;
    }

    private formatDateToMMDDhhmm(datestring: any) {
        if (!datestring) {
            return '';
        }
        // 2020-11-06T00:05:38.263754+07:00
        // let momentObj = moment(datestring, "YYYY-MM-DDTHH:mm:ss.ssssssTZD");
        // let showDate = moment(momentObj).format('MM-DD HH:mm');
        // return showDate;
        let value = datestring.split('.');
        if (value.length > 0) {
            return this.formatShortDateToMMDDhhmm(value[0]);
        }
        return '';
    }

    private formatShortDateToMMDDhhmm(datestring: any) {
        if (!datestring) return '';
        // "updDT": "2020-03-19T12:50:32",
        let momentObj = moment(datestring, "YYYY-MM-DDTHH:mm:ss");
        let showDate = moment(momentObj).format('MM-DD HH:mm');
        return showDate;
    }


    public setWidth(width: number) {
        const navLeft: any = document.getElementById('navLeft');
        this.setState({ freeSizeOpen: false });
        navLeft.style.width = width + 'px';
    }

    public getTimeArrivalRemain(item: any) {
        if (!item.timeArrive) {
            return '';
        }
        return item.timeArriveRemain;
    }

    private getWarningTimeBackgroundColor(data: any) {
        //warningTime > timeArrive => yellow
        //warningTime > pausDT => red
        //warningTime > now() => red
        if (data.warningTime && data.arrivalStatusText != '終了' && data.imei) {
            const warningTime = new Date(data.warningTime);
            const pausDT = new Date(data.pausDT);
            const timeArrive = new Date(data.timeArrive);
            const now = new Date();

            if (data.status == 2) {
                if (data.operCount == 0 && data.departureStatusText != '開始' && data.arrivalStatusText != '終了') {
                    if (warningTime <= pausDT) return "red";
                } else {
                    if (warningTime <= now) return "red";
                    if (warningTime <= timeArrive) return "yellow";
                }
            }
        }
        return "";
    }

    private getConcreteTimeLimitBackgroundColor(data: any) {
        //concreteTimeLimit - pumpingTime > now() => yellow
        //concreteTimeLimit  > now() => red

        if (data.concreteTimeLimit && data.arrivalStatusText != '終了' && data.imei) {
            const concreteTimeLimit = new Date(data.concreteTimeLimit);
            const now = new Date()
            if (data.status == 2) {
                if (concreteTimeLimit <= now) return "red";

                if (data.operCount == 0 && data.departureStatusText != '開始' && data.arrivalStatusText != '終了') {

                    if (moment(concreteTimeLimit).subtract(data.pumpingTime, 'minute').toDate() <= now) return "yellow";
                }
            }
        }
        return "";
    }


    public render() {
        return (
            <aside className={"text-center h-100 " + this.getOpenClass()} id="navLeft">
                <div className="area-title">
                    <FontAwesomeIcon icon={faUser}></FontAwesomeIcon> 端末一覧
                </div>

                <div id="accordion">
                    <div className="card h-100">
                        <Dropdown>
                            <div className="card-header" id="headingThree">
                                {/* <Link to="/TerminalInformation"><a className="collapsible-link d-block">端末</a></Link> */}
                                {this.state.isAdmin ?
                                    <>
                                        <a href="#" onClick={this.onAddItem} className="collapsible-link d-block">端末</a>
                                        <a href="#" onClick={this.onAddMultiItem} className="collapsible-link d-block">端末一括設定</a>
                                    </>
                                    : (<div style={{ height: "25px" }}></div>)
                                }
                                <div className="controls-more">
                                    <Dropdown.Toggle variant="success" id="dropdown-terminal-option">
                                        <FontAwesomeIcon icon={faEllipsisV}></FontAwesomeIcon>
                                    </Dropdown.Toggle>
                                </div>
                                <div>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={() => this.onDropdownSelected(1, true)}>アイコン切替</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => this.onDropdownSelected(2, true)}>コメントを表示</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => this.onDropdownSelected(2, false)}>コメントを非表示</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => this.onDropdownSelected(3, true)}>端末全て表示</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => this.onDropdownSelected(3, false)}>端末全て非表示</Dropdown.Item>
                                    </Dropdown.Menu>
                                </div>
                            </div>
                        </Dropdown>
                        <Collapse in={!this.state.isCollapse}>
                            <div className="card-body h-100" id="div-terminal-list">
                                <div className="table-responsive">
                                    <table className="table table-bordered" style={{ width: '620px' }} id='terminalTable'>
                                        <thead>
                                            <tr>
                                                {/*<th style={{ width: '40px' }}>GPS</th>*/}
                                                <th style={{ width: '40px' }}>往復</th>
                                                <th style={{ width: '90px' }}>端末名</th>
                                                <th style={{ width: '90px' }}>所有者</th>
                                                <th style={{ width: '80px' }}>GPS最終更新日時</th>
                                                <th style={{ width: '70px' }}>電池残量(%)</th>
                                                <th style={{ width: '80px' }}>

                                                    {
                                                        this.state.pitaVersion?.toString() === '2' ? "到着(予想)時刻" : "到着予想時刻"
                                                    }

                                                </th>
                                                <th style={{ width: '70px' }}>ルートID</th>
                                                <th style={{ width: '80px' }}>残り時間(分)</th>

                                                {
                                                    this.state.pitaVersion?.toString() === '2' && (
                                                        <>
                                                            <th style={{ width: '70px' }}>運行自主警戒時刻</th>
                                                            <th style={{ width: '70px' }}>打設制限時刻</th>
                                                        </>
                                                    )
                                                }
                                                <th style={{ width: '50px' }}>表示</th>
                                                <th style={{ width: '50px' }}> </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.terminalData.map((item, index) => (
                                                <tr key={"terminalData" + index} className={item.urgentFlg ? "blink-bg clickable" : "clickable"} onClick={() => this.mapFocusTo(item)}>
                                                    {/*<td className={this.statePosHed(item)}>●</td>*/}
                                                    <td className={this.stateTrips(item)}>●</td>
                                                    <td className={this.terminalStatus(item)}>{item.cliNm}</td>
                                                    <td>{item.driverNm}</td>
                                                    <td>{item.imei ? this.formatShortDateToMMDDhhmm(item.updDT) : ''}</td>
                                                    <td style={{ backgroundColor: item.chargeConnect ? 'white' : 'red' }}>{item.chargedPercent}</td>
                                                    <td>{this.formatDateToMMDDhhmm(item.timeArrive)}</td>
                                                    <td>{item.routeID === '0' ? '' : item.routeID}</td>
                                                    <td>{item.isArrived === true ? '到着' : this.getTimeArrivalRemain(item)}</td>

                                                    {
                                                        this.state.pitaVersion?.toString() === '2' && (
                                                            <>
                                                                <td style={{ backgroundColor: `${this.getWarningTimeBackgroundColor(item)}` }}>{(item.arrivalStatusText != '終了') && (item.imei) ? this.formatShortDateToMMDDhhmm(item.warningTime) : ''}</td>
                                                                <td style={{ backgroundColor: `${this.getConcreteTimeLimitBackgroundColor(item)}` }}>{(item.arrivalStatusText != '終了') && (item.imei) ? this.formatShortDateToMMDDhhmm(item.concreteTimeLimit) : ''}</td>
                                                            </>
                                                        )
                                                    }
                                                    <td><input type="checkbox"
                                                        onClick={(e: any) => e.stopPropagation()}
                                                        checked={item.display}
                                                        onChange={(event) => {
                                                            this.onCheckboxChanged(item, index, event);
                                                            if (event.target.checked) this.mapFocusTo(item);

                                                        }} /></td>
                                                    <td>
                                                        <Button onClick={(e: any) => {
                                                            this.onEditDeleteItem(item, index);
                                                            e.stopPropagation();
                                                        }} >
                                                            <FontAwesomeIcon icon={faEdit} color="#000000"></FontAwesomeIcon>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </aside>
        );
    }
}
