import { faEdit, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from "js-cookie";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import GlobalLoader from "../../common/interceptorLoader";
import { toastOptions } from "../../common/Utils";
import "./EmailTemplateManagement.css";
function debounce(func: any, delay: any) {
  let timeoutId: any;

  return function (...args: any) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  }; 
}
export default class EmailTemplateManagement extends React.Component<
  {},
  {
    listData: any[];
    sectionButton: any;
    sectionTitle: any;
    mailID: any;
    mailSubject: any;
    mailBody: any;
    error: any;
    showEditComponent: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      listData: [],
      sectionTitle: "メール編集",
      sectionButton: "操作",
      mailID: 0,
      mailSubject: "",
      mailBody: "",
      error: "",
      showEditComponent: false,
    };

    this.getlistData = this.getlistData.bind(this);
    this.renderMailType = this.renderMailType.bind(this);
    this.onEditItem = this.onEditItem.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
    this.handleMailBodyChange = debounce(
      this.handleMailBodyChange.bind(this),
      300
    );
  }

  componentDidMount() {
    this.getlistData();
  }

  private getlistData() {
    axiosInstance
      .get(HOST() + "/Form/V1/Mst/MailTemplate/GetList")
      .then((response) => {
        this.setState({ listData: response.data.data });
      });
  }

  private renderMailType(id: number) {
    let message;

    switch (id) {
      case 101:
        message = "エリア進入";
        break;
      case 102:
        message = "エリア退出";
        break;
      case 103:
        message = "速度超過";
        break;
      case 104:
        message = "ルート逸脱";
        break;
      case 105:
        message = "加速度";
        break;
      case 106:
        message = "近接";
        break;
      default:
        message = "";
        break;
    }

    return message;
  }

  public onCancel() {
    if (this.state.showEditComponent) {
      this.setState({ showEditComponent: false });
    } else {
      Cookies.remove("jwt");
      Cookies.remove("user");
      Cookies.remove("pv");
      window.location.href = "/login";
    }
  }

  public onEditItem(object: any) {
    this.setState({
      showEditComponent: true,
      mailID: object.mailID,
      mailSubject: object.mailSubject,
      mailBody: object.mailBody,
    });
  }
  handleMailBodyChange(value: any) {
    this.setState({ mailBody: value });
  }
  public handleSubmitAdd() {
    // if (!isValid) {
    //   this.setState({ error: "メールアドレスを入力してください。" });
    //   return;
    // }
    if (
      !this.state.mailSubject ||
      this.state.mailBody.trim() === "<p><br></p>"
    ) {
      const missingFields = [];
      if (!this.state.mailSubject) {
        missingFields.push("Email Subject");
      }
      if (this.state.mailBody.trim() === "<p><br></p>") {
        missingFields.push("Email Body");
      }
      toast.error(
        `${missingFields.join(", ")} のフィールドは空白にできません。`,
        {
          position: "top-right",
          autoClose: 5000,
          draggable: true,
          closeOnClick: true,
        }
      );
      return;
    }
    var body = {
      mailID: this.state.mailID,
      mailSubject: this.state.mailSubject,
      mailBody: this.state.mailBody,
    };
    const api = axiosInstance.post(
      HOST() + "/Form/V1/Mst/MailTemplate/Update",
      body
    );
    api
      .then((res) => {
        if (res.status == 200 && res.data) {
          this.getlistData();
          toast.success("更新しました。", toastOptions);
          this.setState({ showEditComponent: false });
        } else {
          toast.error("更新に失敗しました。", toastOptions);
        }
      })
      .catch((e) => {
        this.setState({ error: "Request Failed" });
      });
  }

  public render() {
    return (
      <div>
        <GlobalLoader />
        <ToastContainer />
        <div className="box">
          <div className="box-title d-flex justify-content-between align-items-center">
            <span className="page-header">メールテンプレート設定</span>
            <div className="buttonEdit">
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => this.onCancel()}>
                <span>ログアウト</span>
                <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>
              </button>
            </div>
          </div>

          <header className="th-header"></header>
          <div
            className={
              this.state.showEditComponent
                ? "tableEmailMngEdit"
                : "tableEmailMngNormal"
            }>
            <table id="sites" style={{ marginBottom: "10px" }}>
              <tbody>
                <tr>
                  <th style={{ width: "15%" }}>種類</th>
                  <th style={{ width: "15%" }}>件名</th>
                  <th style={{ width: "60%" }}>内容</th>
                  <th></th>
                </tr>
                {this.state.listData.map((item, index) => (
                  <tr key={`mailSetting${item.mailID}&${index}`}>
                    <td style={{ textAlign: "left" }}>
                      {this.renderMailType(item.mailID)}
                    </td>
                    <td>{item?.mailSubject}</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: item?.mailBody,
                      }}></td>
                    <td>
                      <Button
                        onClick={() => this.onEditItem(item)}
                        className="button-img btn-50">
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="#000000"></FontAwesomeIcon>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            id="addComponent"
            style={{
              display: this.state.showEditComponent ? "block" : "none",
            }}>
            <form>
              <header className="th-header bg-blue">
                <span
                  style={{
                    position: "absolute",
                    marginTop: "10px",
                    left: "45%",
                    fontSize: "15px",
                  }}>
                  {this.state.sectionTitle}
                </span>
              </header>
              <table onSubmit={this.handleSubmitAdd}>
                <tbody>
                  <tr>
                    <th style={{ width: "15%" }}>種類</th>
                    <th style={{ width: "15%" }}>件名</th>
                    <th style={{ width: "60%" }}>内容</th>
                    <th style={{ width: "10%" }}></th>
                  </tr>
                  <tr>
                    <td>{this.renderMailType(this.state.mailID)}</td>
                    <td>
                      <input
                        name="mailAddress"
                        type="text"
                        value={this.state.mailSubject}
                        onChange={(e) =>
                          this.setState({ mailSubject: e.target.value })
                        }
                        style={{ width: "100%" }}
                        maxLength={100}
                      />
                      <br />
                      <span className="errClass">{this.state.error}</span>
                    </td>
                    <td>
                      <div style={{ height: "300px", overflowY: "auto" }}>
                        <ReactQuill
                          value={`${this.state.mailBody}`}
                          onChange={this.handleMailBodyChange}
                        />
                      </div>
                    </td>
                    <td>
                      {/* <input type="submit" value="登録" className="cancel-button register"/> */}
                      <Button
                        onClick={this.handleSubmitAdd}
                        className="btn btn-primary btn-sm register">
                        更新
                      </Button>
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({ showEditComponent: false })
                        }
                        className="btn btn-warning btn-sm register mt-1">
                        キャンセル
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    );
  }
}