import React from "react";
import "./SearchMarker.css";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
interface SearchMarkerState {
  latitude: string;
  longitude: string;
  currentMarker: google.maps.Marker | null;
}
export default class SearchMarker extends React.Component<
  {
    map: google.maps.Map;
  },
  SearchMarkerState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      latitude: "",
      longitude: "",
      currentMarker: null,
    };
  }

  componentDidUpdate(prevProps: { map: google.maps.Map | null }) {
    if (!prevProps.map && this.props.map) {
      this.tryInitializeMap();
    }
  }

  tryInitializeMap = () => {
    if (!this.props.map) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const lat = params.get("lat");
    const lng = params.get("lng");

    if (lat && lng && !isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng))) {
      const position = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
      this.props.map.setCenter(position);
      this.props.map.setZoom(19);

      const newMarker = new google.maps.Marker({
        position: position,
        map: this.props.map,
      });

      this.setState({
        latitude: lat,
        longitude: lng,
        currentMarker: newMarker,
      });
    }
    this.props.map.addListener("click", () => {
      if (this.state.currentMarker) {
        this.state.currentMarker.setMap(null);
      }
    });
  };

  public zoomToLocation = () => {
    const { latitude, longitude, currentMarker } = this.state;
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);
    if ((isNaN(lat) && !isNaN(lng)) || (!isNaN(lat) && isNaN(lng))) {
      toast.warn("緯度と経度の両方を入力してください。", {
        position: "top-right",
        autoClose: 5000,
        draggable: true,
        closeOnClick: true,
      });
      return;
    }
    if (!isNaN(lat) && !isNaN(lng)) {
      const position = new google.maps.LatLng(lat, lng);
      this.props.map.setCenter(position);
      this.props.map.setZoom(19);
      if (currentMarker) {
        currentMarker.setMap(null);
      }
      const newMarker = new google.maps.Marker({
        position: position,
        map: this.props.map,
      });

      this.setState({ currentMarker: newMarker });

      const url = new URL(window.location.href);
      url.searchParams.set("lat", lat.toString());
      url.searchParams.set("lng", lng.toString());
      window.history.replaceState({}, "", url.toString());

    } else {
      const mapDefault: any = Cookies.get("mapDefault");
      if (mapDefault) {
        const mapPosData = JSON.parse(mapDefault);
        const url = new URL(window.location.href);
        url.searchParams.set("lat", mapPosData.lat.toString());
        url.searchParams.set("lng", mapPosData.lng.toString());
        window.history.replaceState({}, "", url.toString());
        const position = new google.maps.LatLng(mapPosData.lat, mapPosData.lng);
        this.props.map.setCenter(position);
        this.props.map.setZoom(14);
        if (currentMarker) {
          currentMarker.setMap(null);
        }
        const newMarker = new google.maps.Marker({
          position: position,
          map: this.props.map,
        });

        this.setState({
          currentMarker: newMarker,
          latitude: mapPosData.lat,
          longitude: mapPosData.lng,
        });
      } else {
        toast.warn("緯度と経度の両方を入力してください。", {
          position: "top-right",
          autoClose: 5000,
          draggable: true,
          closeOnClick: true,
        });
      }
    }
  };

  public render() {
    return (
      <div className="SearchMarker">
        <div className="coordinate-inputs">
        {/*  <input*/}
        {/*    type="number"*/}
        {/*    value={this.state.latitude}*/}
        {/*    placeholder="緯度"*/}
        {/*    onChange={(e) => this.setState({ latitude: e.target.value })}*/}
        {/*    onKeyDown={(e) => {*/}
        {/*      if (e.key === "Enter") {*/}
        {/*        this.zoomToLocation();*/}
        {/*      }*/}
        {/*    }}*/}
        {/*  />*/}
        {/*  <input*/}
        {/*    type="number"*/}
        {/*    value={this.state.longitude}*/}
        {/*    placeholder="経度"*/}
        {/*    onChange={(e) => this.setState({ longitude: e.target.value })}*/}
        {/*    onKeyDown={(e) => {*/}
        {/*      if (e.key === "Enter") {*/}
        {/*        this.zoomToLocation();*/}
        {/*      }*/}
        {/*    }}*/}
        {/*  />*/}
        {/*  <button onClick={this.zoomToLocation}>表示</button>*/}
        </div>
      </div>
    );
  }
}
