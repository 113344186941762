import * as React from 'react';
import './OperationHistory.css';
import axiosInstance from '../../common/interceptor';
import { HOST, UNSET_OPTION } from '../../common/constant';
import OperationHistoryList from './OperationHistoryList';
import DateTimeFromTo from '../../common/DateTimeFromTo/DateTimeFromTo';
import BaseSelectList from '../../common/BaseSelectList/BaseSelectList';
import FileDownload from 'js-file-download';
import { calculatePath, reloadTerminal, toastOptions } from '../../common/Utils';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import eventBus from "../../common/EventBus"
import moment from 'moment';

export default class OperationHistory extends React.Component<{
    map: any,
    onNavi: Function
}, {
    Terminal: any,
    TerminalName: any,
    dateFrom: any,
    timeFrom: any,
    dateTo: any,
    timeTo: any,
    OperationHistoryList: any[],
    Path: any,
    isEco: any,
    orderBy: any,
    isAsc: any,
    pitaVersion: string,
    validating: boolean
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            Terminal: UNSET_OPTION,
            TerminalName: '',
            dateFrom: undefined,
            dateTo: undefined,
            timeFrom: '',
            timeTo: '',
            OperationHistoryList: [],
            Path: null,
            isEco: false,
            orderBy: undefined,
            isAsc: undefined,
            pitaVersion: "0",
            validating: false
        };
    }

    componentDidMount() {
        const DateTimeFromTo: any = this.refs.DateTimeFromTo;
        DateTimeFromTo.initDefaultTime();

        var user: any = JSON.parse(Cookies.get('user') || "{}");
        if (user) {

            this.checkPitaVersion(user.pitaVersion);


        }
        eventBus.on("changeDetailSetting", (data: any) =>
            this.checkPitaVersion(data.pitaVersion)
        );

        eventBus.on("changeRecArea", (data: any) => {
            //kiểm tra recAreas
            this.search();
        });
    }

    private checkPitaVersion(pitaVersion: string) {
        this.setState({ pitaVersion: pitaVersion });
    }

    search(hasSort = false) {
        const bottomTab: any = document.getElementById("bottomTab");
        const thisHeight = bottomTab.style.height;
        const heightTabcontent = parseFloat(thisHeight) - 130;
        if (heightTabcontent > 65) {
            const OperationHistoryList: any = document.getElementById("OperationHistoryList");
            if (OperationHistoryList) OperationHistoryList.style.height = `${heightTabcontent}px`;
        }
        const DateTimeFromTo: any = this.refs.DateTimeFromTo;
        const { fromDate, toDate } = DateTimeFromTo.getDateTime();
        var duration = moment.duration(moment(toDate).diff(moment(fromDate)));
        var days = duration.asDays();
        this.setState({ validating: days > 30 });
        if (days > 30) {
            return;
        }
        this.clearPath();
        const CliId = this.state.Terminal ? `&CliID=${this.state.Terminal}` : '';
        const OrderBy = this.state.orderBy && hasSort ? `&orderBy=${this.state.orderBy}` : '';
        const IsAsc = this.state.isAsc != undefined && hasSort ? `&isAsc=${this.state.isAsc}` : '';
        if (!hasSort) {
            this.setState({ orderBy: undefined, isAsc: undefined });
            const OperationHistoryList: any = this.refs.OperationHistoryList;
            OperationHistoryList.clearSort();
        }

        axiosInstance
            .get(HOST() + `/Form/V1/Dat/Report/OperationHist?fromDate=${fromDate}&toDate=${toDate}${CliId}${OrderBy}${IsAsc}`)
            .then(response => {
                this.clearPath();
                this.setState({ OperationHistoryList: response.data.data || [], isEco: response.data.code == "True" });
            });
    }

    async register() {
        const Path = this.state.Path;
        var pathData = Path.map((e: any, i: any) => {
            return {
                posID: i,
                posLat: e.posLat,
                lat: e.posLat,
                posLon: e.posLon,
                lng: e.posLon,
            }
        });

        const res: any = await calculatePath(pathData[0], pathData[pathData.length - 1]);

        const naviData = {
            pathEncoded: pathData,
            routeId: 0,
            GoTime: Math.round(res.GoTime / 60),
            ReTime: Math.round(res.ReTime / 60),
            Distance: res.Distance,
        };
        this.props.onNavi('/editRouteManagement', naviData);
    }

    async exportData() {
        const link: any = document.createElement("a");
        const DateTimeFromTo: any = this.refs.DateTimeFromTo;
        const { fromDate, toDate } = DateTimeFromTo.getDateTime();
        const CliId = this.state.Terminal ? `&CliID=${this.state.Terminal}` : '';
        let downloadFileName = '運行履歴_' + this.state.TerminalName + "_";
        downloadFileName += fromDate.substr(0, 10).replaceAll('-', '') + "_";
        downloadFileName += toDate.substr(0, 10).replaceAll('-', '');

        const OrderBy = this.state.orderBy ? `&orderBy=${this.state.orderBy}` : '';
        const IsAsc = this.state.isAsc ? `&isAsc=${this.state.isAsc}` : '';
        axiosInstance({
            url: HOST() + `/Form/V1/Dat/Export/OperationHist?fromDate=${fromDate}&toDate=${toDate}${CliId}${OrderBy}${IsAsc}`,
            method: 'GET',
            responseType: 'blob',
        })
            .then((response) => {
                if (response.status != 200) {
                    toast.error('ダウンロードに失敗しました。', toastOptions);
                } else {
                    FileDownload(response.data, `${downloadFileName}.xls`);
                }
            });
    }

    clearPath() {
        const OperationHistoryList: any = this.refs.OperationHistoryList;
        OperationHistoryList.clearPath(true);
    }

    selectOnChange = (e: any, l: any, eco: any) => {
        let ecoCheck = false;
        if (Array.isArray(eco)) {
            ecoCheck = eco.reduce((e, f) => e || f, false);
        } else {
            ecoCheck = eco;
        }
        this.setState({ Terminal: e.target.value, TerminalName: l, isEco: ecoCheck })
    }

    doSort = (state: any) => {
        this.setState({
            orderBy: state.sortCol,
            isAsc: state.sortDirectionAsc,
        }, () => this.search(true));
    }

    user: any = JSON.parse(Cookies.get('user') || "{}");
    public render() {
        return (
            <div className="operation-history-container">
                <div className="mb-2 row">
                    <div className="col-xl-2 col-lg-4">
                        <div className="d-flex">
                            <label className="col-form-label form-control-label text-nowrap mr-2">端末</label>
                            <span className="TerminalList" onClick={() => reloadTerminal(this)}></span>
                            <BaseSelectList
                                ref="TerminalList"
                                className=""
                                dkey="cliID"
                                dvalue="cliNm"
                                nullLabel='すべて端末'
                                fetchUrl={`/Form/V1/Mst/Terminal/GetList?sysID=${this.user.siteId}`}
                                optKey='accelAlert'
                                onChange={this.selectOnChange}
                                value={this.state.Terminal}
                            />
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-8">
                        <div className="d-flex justify-content-center align-items-center">
                            <label className="col-form-label form-control-label mr-2 text-nowrap">作業日</label>
                            <DateTimeFromTo
                                ref="DateTimeFromTo"
                                className="OH-input"
                                dateFrom={this.state.dateFrom}
                                timeFrom={this.state.timeFrom}
                                dateTo={this.state.dateTo}
                                timeTo={this.state.timeTo}
                                onChange={(e: any) => this.setState(e)}
                            />
                        </div>
                        {
                            this.state.validating
                                ? <div className="d-flex justify-content-center"><div className="text-error ml-2 text-nowrap">期間が30日を超える日付は指定できません</div> </div>
                                : <span></span>

                        }

                    </div>
                    <div className="col-xl-4 col-lg-12 text-right">
                        <button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => this.clearPath()}>クリア</button>
                        <button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => this.search()}>検索</button>
                        <button
                            type="button"
                            className="btn btn-primary btn-sm mr-2"
                            onClick={() => this.register()}
                            disabled={this.state.Path == null}
                        > ルートとして登録</button >

                        <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => this.exportData()}
                            disabled={this.state.OperationHistoryList.length < 1}
                        >帳票出力</button >
                    </div>
                </div>
                <OperationHistoryList
                    OperationHistoryList={this.state.OperationHistoryList}
                    map={this.props.map}
                    ref="OperationHistoryList"
                    updatePath={(e: any) => this.setState({ Path: e })}
                    isEco={this.state.isEco}
                    doSort={this.doSort}
                />
            </div>
        );
    }
}
