import * as React from 'react';
import './Trajectory.css';
import axiosInstance from '../../common/interceptor';
import { HOST, UNSET_OPTION } from '../../common/constant';
import { calculatePath, drawPathToMap, reloadTerminal, toastOptions } from '../../common/Utils';
import DateTimeFromTo from '../../common/DateTimeFromTo/DateTimeFromTo';
import BaseSelectList from '../../common/BaseSelectList/BaseSelectList';
import { toast } from 'react-toastify';
import FileDownload from 'js-file-download';
import Cookies from 'js-cookie';
import moment from 'moment';

export default class Trajectory extends React.Component<{
	map: any,
	onNavi: Function
}, {
	Terminal: any,
	TerminalName: any,
	dateFrom: any,
	timeFrom: any,
	dateTo: any,
	timeTo: any,
	Path: any[],
	validating: boolean
}> {
	constructor(props: any) {
		super(props);
		this.state = {
			Terminal: UNSET_OPTION,
			TerminalName: '',
			dateFrom: undefined,
			dateTo: undefined,
			timeFrom: '',
			timeTo: '',
			Path: [],
			validating: false
		};
	}

	polyline: any = null;
	fromMarker: any = null;
	toMarker: any = null;

	componentDidMount() {
		const DateTimeFromTo: any = this.refs.DateTimeFromTo;
		DateTimeFromTo.initDefaultTime();
	}

	search() {
		this.clearPath();
		const DateTimeFromTo: any = this.refs.DateTimeFromTo;
		const { fromDate, toDate } = DateTimeFromTo.getDateTime();
		var duration = moment.duration(moment(toDate).diff(moment(fromDate)));
		var days = duration.asDays();
		this.setState({ validating: days > 30 });
		if (days > 30) {
			return;
		}
		const CliId = this.state.Terminal ? `&CliID=${this.state.Terminal}` : '';
		axiosInstance
			.get(HOST() + `/Form/V1/Dat/Report/TerminalGPS?fromDate=${fromDate}&toDate=${toDate}${CliId}`)
			.then(response => this.drawRoute(response.data.data));
	}

	private drawRoute(path: any[]) {
		if (!path || path.length == 0) {
			toast.error('軌跡が見つかりません。', toastOptions);
			return;
		}

		const drawedData = drawPathToMap(path, this.props.map, '3/169/244', true);
		this.fromMarker = drawedData.drawedPath.fromMarker;
		this.toMarker = drawedData.drawedPath.toMarker;
		this.polyline = drawedData.drawedPath.polyline;
		path.forEach((e: any, i: any) => e.PosID = i);
		this.setState({ Path: path });
	}

	async register() {
		const Path = this.state.Path;
		var pathData = Path.map((e: any, i: any) => {
			return {
				posID: i,
				posLat: e.posLat,
				lat: e.posLat,
				posLon: e.posLon,
				lng: e.posLon,
			}
		});

		const res: any = await calculatePath(pathData[0], pathData[pathData.length - 1]);

		const naviData = {
			pathEncoded: pathData,
			routeId: 0,
			GoTime: Math.round(res.GoTime / 60),
			ReTime: Math.round(res.ReTime / 60),
			Distance: res.Distance,
		};
		this.props.onNavi('/editRouteManagement', naviData);
		this.clearPath();
	}

	async exportData() {
		const link: any = document.createElement("a");
		const DateTimeFromTo: any = this.refs.DateTimeFromTo;
		const { fromDate, toDate } = DateTimeFromTo.getDateTime();
		const CliId = this.state.Terminal ? `&CliID=${this.state.Terminal}` : '';

		let downloadFileName = '運行グラフ_' + this.state.TerminalName + "_";
		downloadFileName += fromDate.substr(0, 10).replaceAll('-', '') + "-";
		downloadFileName += toDate.substr(0, 10).replaceAll('-', '');

		axiosInstance({
			url: HOST() + `/Form/V1/Dat/Export/GPSHist?fromDate=${fromDate}&toDate=${toDate}${CliId}`,
			method: 'GET',
			responseType: 'blob',
			timeout: 300000
		})
			.then((response) => {
				if (response.status != 200) {
					toast.error('ダウンロードに失敗しました。', toastOptions);
				} else {
					FileDownload(response.data, `${downloadFileName}.xls`);
				}
			});
	}

	componentWillUnmount() {
		this.clearPath();
	}

	private clearPath() {
		this.fromMarker && this.fromMarker.setMap(null);
		this.toMarker && this.toMarker.setMap(null);
		this.polyline && this.polyline.setMap(null);
		this.setState({ Path: [] });
	}
	user: any = JSON.parse(Cookies.get('user') || "{}");

	public render() {
		return (
			<div className="trajectory-container">

				<div className="form-row row">
					<div className="col-xl-2 col-lg-4 d-flex">
						<label className="col-form-label form-control-label text-nowrap mr-2">端末</label>
						<span className="TerminalList" onClick={() => reloadTerminal(this)}></span>
						<BaseSelectList
							className=""
							dkey="cliID"
							dvalue="cliNm"
							ref="TerminalList"
							nullLabel={null}
							fetchUrl={`/Form/V1/Mst/Terminal/GetList?sysID=${this.user.siteId}`}
							onChange={(e: any, l: any) => this.setState({ Terminal: e.target.value, TerminalName: l })}
							value={this.state.Terminal}
						/>
					</div>
					<div className="col-xl-6 col-lg-8">
						<div className="d-flex justify-content-center align-items-center">
							<label className="col-form-label form-control-label mr-2 text-nowrap">範囲</label>
							<DateTimeFromTo
								ref="DateTimeFromTo"
								className=""
								dateFrom={this.state.dateFrom}
								timeFrom={this.state.timeFrom}
								dateTo={this.state.dateTo}
								timeTo={this.state.timeTo}
								onChange={(e: any) => this.setState(e)}
							/>
						</div>
						{
							this.state.validating
								? <div className="d-flex justify-content-center"><div className="text-error ml-2 text-nowrap">期間が30日を超える日付は指定できません</div> </div>
								: <span></span>
						}
					</div>
					<div className="col-xl-4 col-lg-12 text-right">
						<button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => this.clearPath()}>クリア</button>
						<button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => this.search()}>軌跡</button>
						<button
							type="button"
							className="btn btn-primary btn-sm mr-2"
							onClick={() => this.register()}
							disabled={this.state.Path.length == 0}
						> ルートとして登録</button >

						<button
							type="button"
							className="btn btn-primary btn-sm"
							onClick={() => this.exportData()}
							disabled={this.state.Path.length == 0}
						> グラフ出力</button >
					</div>
				</div>
			</div>
		);
	}
}
