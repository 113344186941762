import React, { useEffect, useState } from "react";
import "./Setting.css"
import "./DeviceDisplaySetting.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDoubleUp, faAngleDown, faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import axiosInstance from "../../common/interceptor";
import { HOST } from "../../common/constant";
import { toast } from "react-toastify";
import { toastOptions } from "../../common/Utils";

type DeviceDisplaySettingProp = {
    onNavi: Function
}

const DeviceDisplaySetting = (props: DeviceDisplaySettingProp) => {

    const [listData, setListData] = useState<any[]>([])
    const [itemSelected, setItemSelected] = useState<string>("")
    const userData = Cookies.get('user');
    const user = JSON.parse(userData ? userData : '{}');
    const key = `CliDisplay_${user ? user.loginID : 'Default'}`;

    useEffect(() => {
        if (listData.length == 0) {
            const cliDisplay = Cookies.get(key);
            if (cliDisplay) {
                const data = JSON.parse(cliDisplay);
                if (data && data.length > 0) {
                    setListData(data.sort(function (a: any, b: any) {
                        return a.numOrder - b.numOrder;
                    }))
                }
            } else {
                getDefaultData();
            }
        }
    }, [user, key])

    const getDefaultData = () => {
        var api = "/Form/V1/Mst/Setting/GetCliDisplay"
        axiosInstance
            .get(HOST() + api)
            .then(response => {
                if (response.data.data && response.data.data.length > 0) {
                    const data = response.data.data.sort(function (a: any, b: any) {
                        return a.numOrder - b.numOrder;
                    });
                    setListData(data);
                    Cookies.set(key, JSON.stringify(data));
                }
            })
    }

    const onChangeDisplay = (isDisplay: boolean, index: number) => {
        let newData = [...listData];
        newData[index].isDisplay = isDisplay;
        setListData(newData);
    }

    const handleMoveTop = () => {
        let newData = [...listData];
        const iUpdate = newData.findIndex(x => x.fieldName == itemSelected);
        let numOrder = 2;
        for (let index = 0; index < newData.length; index++) {
            if (iUpdate == index) {
                newData[index].numOrder = 1;
            } else {
                newData[index].numOrder = numOrder;
                numOrder++;
            }
        }

        sortData(newData);
    }

    const handleMoveUp = () => {
        let newData = [...listData];
        const iUpdate = newData.findIndex(x => x.fieldName == itemSelected);
        newData[iUpdate].numOrder = newData[iUpdate].numOrder - 1;
        newData[iUpdate - 1].numOrder = newData[iUpdate - 1].numOrder + 1;
        sortData(newData);
    }

    const handleMoveDown = () => {
        let newData = [...listData];
        const iUpdate = newData.findIndex(x => x.fieldName == itemSelected);
        newData[iUpdate].numOrder = newData[iUpdate].numOrder + 1;
        newData[iUpdate + 1].numOrder = newData[iUpdate + 1].numOrder - 1;
        sortData(newData);
    }

    const handleMoveBottom = () => {
        let newData = [...listData];
        const iUpdate = newData.findIndex(x => x.fieldName == itemSelected);
        let numOrder = 1;
        for (let index = 0; index < newData.length; index++) {
            if (iUpdate == index) {
                newData[index].numOrder = newData.length;
            } else {
                newData[index].numOrder = numOrder;
                numOrder++;
            }
        }

        sortData(newData);
    }

    const sortData = (newData: any[]) => {
        const data = newData.sort(function (a: any, b: any) {
            return a.numOrder - b.numOrder;
        });
        setListData(data);
    }

    const onCancel = (path: any) => {
        props.onNavi('/');
    }

    const handleSave = () => {
        Cookies.set(key, JSON.stringify(listData));
        toast.success('更新に成功。', toastOptions);
    }

    return (
        <div>
            <div className="box device-display">
                <div className="box-title d-flex justify-content-between align-items-center">
                    <span className="page-header">端末一覧表示設定</span>
                    <div className="buttonEdit">
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={onCancel} >キャンセル</button>
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={handleSave}> 保存</button >
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="buttonEdit">
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135 btn-white" onClick={getDefaultData} >初期状態に戻す</button>
                    </div>
                </div>
            </div>
            <div className="d-flex">
                <table style={{ marginBottom: "10px", width: '100%' }}>
                    <tbody>
                        <tr>
                            <th style={{ width: '50px' }}>表示</th>
                            <th style={{ width: '300px' }}>項目名</th>
                        </tr>

                        {listData.map((item: any, index) => (
                            <tr key={index} onClick={() => setItemSelected(item.fieldName)} className={itemSelected == item.fieldName ? 'item-selected' : ''}>
                                <td><input type="checkbox" checked={item.isDisplay} onChange={e => onChangeDisplay(e.target.checked, index)} /></td>
                                <td>{item.fieldDesc}</td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
                <div className="d-flex btn-move">
                    <button type="button" className="btn btn-secondary btn-sm btn-white" disabled={listData.length > 0 && itemSelected == listData[0].fieldName} onClick={handleMoveTop} >
                        <FontAwesomeIcon icon={faAngleDoubleUp} size="2x" color="#6f7175"></FontAwesomeIcon>
                    </button>
                    <button type="button" className="btn btn-secondary btn-sm btn-white" onClick={handleMoveUp}>
                        <FontAwesomeIcon icon={faAngleUp} size="2x" color="#6f7175"></FontAwesomeIcon>
                    </button >
                    <button type="button" className="btn btn-secondary btn-sm btn-white" onClick={handleMoveDown} >
                        <FontAwesomeIcon icon={faAngleDown} size="2x" color="#6f7175"></FontAwesomeIcon>
                    </button>
                    <button type="button" className="btn btn-secondary btn-sm btn-white" disabled={listData.length > 0 && itemSelected == listData[listData.length - 1].fieldName} onClick={handleMoveBottom}>
                        <FontAwesomeIcon icon={faAngleDoubleDown} size="2x" color="#6f7175"></FontAwesomeIcon>
                    </button >
                </div>
            </div>
        </div>
    )
}

export default DeviceDisplaySetting;