import React, { useEffect, useState } from "react";
import "./AccountInfoChange.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";
import userImage from "../../assets/img/user.jpg";
import { isBlank, toastOptions } from "../../common/Utils";
import { toast } from "react-toastify";

export default function AccountInfoChange(props: any) {
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loginId, setLoginId] = useState("");
	const [userName, setUserName] = useState("");
	const [siteName, setSiteName] = useState("");
	const [displayName, setDisplayName] = useState("");
	// const [newEmail, setNewEmail] = useState("");
	// const [confirmEmail, setConfirmEmail] = useState("");

	// const [canChangeEmail, setCanChangeEmail] = useState(false);
	const [canChangePassword, setCanChangePassword] = useState(false);

	const [validating, setValidating] = useState(false);

	const history = useHistory();
	// 	var userData = Cookies.get('user');
	// 	var user = JSON.parse(userData ? userData : '{}');
	// console.log(user)

	useEffect(() => {
		var userData = Cookies.get('user');
		var user = JSON.parse(userData ? userData : '{}');
		if (user) {
			setLoginId(user.loginID);
			setUserName(user.userName);
			setSiteName(user.siteName);
			setDisplayName(user.displayName);
		}
	}, [])


	function validateForm() {
		setValidating(true);
		var valid = true;
		if (isBlank(userName) || isBlank(siteName) || isBlank(displayName)) {
			valid = false;
		}

		// if ((!isEmail(newEmail) || isBlank(confirmEmail) || newEmail !== confirmEmail) && canChangeEmail) {
		// 	valid = false;
		// }

		if (canChangePassword) {
			if (isBlank(password)) {
				valid = false;
			}

			if ((isBlank(newPassword) || isBlank(confirmPassword) || newPassword !== confirmPassword) && canChangePassword) {
				valid = false;
			}
		}

		return valid;
	}

	// function isEmail(str: string) {
	// 	return !isBlank(str) && /^[A-Za-z0-9_\.]{1,32}@[a-zA-Z0-9\-]{2,}(\.[a-zA-Z0-9]{2,4}){1,2}$/.test(str);
	// }

	function validatePassword(str: string) {
		return !isBlank(str) && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(str);
	}

	function handleSubmit(event: { preventDefault: () => void; }) {
		event.preventDefault();
		if (!validateForm()) {
			return;
		}
		var body = {
			loginId: loginId,
			password: password,
			userName: userName,
			siteName: siteName,
			displayName: displayName,
			newPassword: newPassword,
			confirmNewPassword: confirmPassword,
		};
		axiosInstance
			.post(HOST() + "/Form/V1/Mst/UpdateUser", body)
			.then((res: any) => {

				if (res && res.data) {
					if (res.data.code === "1.2.4_001") {
						toast.error("現在のパスワードは正しくありません。", toastOptions);
						return;
					}

					if (res.data.result) {
						toast.success('更新に成功。', toastOptions);

						if (canChangePassword) {
							var body = {
								loginID: loginId,
								password: '**********'
							};
							axiosInstance
								.post(HOST() + "/Form/V1/Mst/logout", body)
								.then((res: any) => {
									if (res.data.result) {
										Cookies.remove('jwt');
										Cookies.remove('user');
										Cookies.remove('pv');
										window.location.href = '/login';
									}
								});
						} else {
							let user = JSON.parse(Cookies.get('user') || "{}");
							user.userName = userName;
							user.siteName = siteName;
							user.displayName = displayName;
							Cookies.set('user', user);
							props.onNavi('/')
						}
					} else {
						toast.error(res.data.message, toastOptions);
						return;
					}
				}



			}).catch(error => {
				if (error.response && error.response.data) {
					if (error.response.data.code === "1.2.4_001") {
						toast.error("現在のパスワードは正しくありません。", toastOptions);
					}
				}
			});

	}

	function onChangeCanChangePassword(checked: boolean) {
		setCanChangePassword(checked);
		if (!checked) {
			setPassword("");
			setNewPassword("");
			setConfirmPassword("");
		}
	}

	return (
		<div>
			<form>
				<div className="user-details box mb-3">
					{/* <div className="user-image">
						<div className="image">
							<div className="bg-transfer"><img src={userImage} className="image" alt="" /></div>
						</div>
					</div> */}
					<div className="description clearfix">
						<h3>ログインID:</h3>
						<h2>{loginId}</h2>
						{/* <h3>現在のメールアドレス:</h3>
						<h2>{user.mailAdd}</h2> */}
					</div>
				</div>
				{/* <div className={(validating && isBlank(password)) ? 'error form-group' : 'form-group'}>
					<label>現在のパスワード：</label>
					<input type="password" className="form-control form-control-sm" onChange={e => setPassword(e.target.value)} />
					{
						validating && isBlank(password)
							? <div className="text-error">現在のパスワード必須項目です。</div>
							: <span></span>
					}
				</div> */}
				<fieldset className="mb-4">
					{/* <legend>
						<div className="custom-control custom-checkbox">
							<input type="checkbox" className="custom-control-input" id="canChangeEmail" onChange={e => setCanChangeEmail(e.target.checked)} />
							<label className="custom-control-label font-weight-bold" htmlFor="canChangeEmail" >メールアドレスの変更</label>
						</div>
					</legend>
					<div className={(!isEmail(newEmail) && canChangeEmail && validating) ? 'error form-group' : 'form-group'}>
						<label >新しいメールアドレス：</label>
						<input
							type="text"
							className="form-control form-control-sm"
							id="newEmail"
							onChange={e => setNewEmail(e.target.value)}
							disabled={!canChangeEmail}
						/>
						{
							validating && isBlank(newEmail) && canChangeEmail
								? <div className="text-error">必須項目です。</div>
								: <span></span>
						}
						{
							validating && !isBlank(newEmail) && !isEmail(newEmail) && canChangeEmail
								? <div className="text-error">メールアドレスが無効です。</div>
								: <span></span>
						}
					</div>
					<div className={(isBlank(confirmEmail) || (newEmail !== confirmEmail && !isBlank(newEmail))) && canChangeEmail && validating ? 'error form-group' : 'form-group'}>
						<label >新しいメールアドレス再入力：</label>
						<input
							type="text"
							className="form-control form-control-sm"
							id="confirmNewEmail"
							onChange={e => setConfirmEmail(e.target.value)}
							disabled={!canChangeEmail}
						/>
						{
							validating && isBlank(confirmEmail) && canChangeEmail
								? <div className="text-error">必須項目です。</div>
								: <span></span>
						}
						{
							validating && !isBlank(newEmail) && !isBlank(confirmEmail) && newEmail !== confirmEmail && canChangeEmail
								? <div className="text-error">メールアドレスをもう一度入力してください。</div>
								: <span></span>
						}
					</div> */}
					<div className={(validating && isBlank(userName)) ? 'error form-group' : 'form-group'}>
						<label>ユーザ名：</label>
						<input className="form-control form-control-sm" value={userName} onChange={e => setUserName(e.target.value)} maxLength={20} />
						{
							validating && isBlank(userName)
								? <div className="text-error">[ユーザ名]を入力してください。</div>
								: <span></span>
						}
					</div>
					<div className={(validating && isBlank(siteName)) ? 'error form-group' : 'form-group'}>
						<label>現場名：</label>
						<input className="form-control form-control-sm" value={siteName} onChange={e => setSiteName(e.target.value)} maxLength={20} />
						{
							validating && isBlank(siteName)
								? <div className="text-error">[現場名]を入力してください。</div>
								: <span></span>
						}
					</div>
					<div className={(validating && isBlank(displayName)) ? 'error form-group' : 'form-group'}>
						<label>表示名：</label>
						<input className="form-control form-control-sm" value={displayName} onChange={e => setDisplayName(e.target.value)} maxLength={10} />
						{
							validating && isBlank(displayName)
								? <div className="text-error">[表示名]を入力してください。</div>
								: <span></span>
						}
					</div>
				</fieldset>
				<fieldset>
					<legend>
						<div className="custom-control custom-checkbox">
							<input
								type="checkbox"
								className="custom-control-input"
								id="canChangePassword"
								onChange={e => onChangeCanChangePassword(e.target.checked)}
							/>
							<label className="custom-control-label font-weight-bold" htmlFor="canChangePassword" >バスワードの変更</label>
						</div>
					</legend>
					<div className={(validating && canChangePassword && isBlank(password)) ? 'error form-group' : 'form-group'}>
						<label>現在のパスワード：</label>
						<input
							type="password"
							className="form-control form-control-sm"
							value={password}
							onChange={e => setPassword(e.target.value)}
							disabled={!canChangePassword}
						/>
						{
							validating && canChangePassword && isBlank(password)
								? <div className="text-error">[現在のパスワード]を入力してください。</div>
								: <span></span>
						}
					</div>
					<div className={(isBlank(newPassword) && canChangePassword && validating) ? 'error form-group' : 'form-group'}>
						<label >新しいバスワード：</label>
						<input
							type="password"
							className="form-control form-control-sm"
							id="newPassword"
							value={newPassword}
							onChange={e => setNewPassword(e.target.value)}
							disabled={!canChangePassword}
						/>
						{
							validating && canChangePassword && isBlank(newPassword)
								? <div className="text-error">[新しいバスワード]を入力してください。</div>
								: <span></span>
						}
						{
							validating && !isBlank(newPassword) && !validatePassword(newPassword) && canChangePassword
								? <div className="text-error">パスワードは8文字以上に、は英大文字、小文字、数字、記号を各1文字以上使用してください。</div>
								: <span></span>
						}
					</div>
					<div className={(isBlank(confirmPassword) || (newPassword !== confirmPassword && !isBlank(newPassword))) && canChangePassword && validating ? 'error form-group' : 'form-group'}>
						<label >新しいパスワード再入力：</label>
						<input
							type="password"
							className="form-control form-control-sm"
							id="confirmNewPassword"
							value={confirmPassword}
							onChange={e => setConfirmPassword(e.target.value)}
							disabled={!canChangePassword}
						/>
						{
							validating && canChangePassword && isBlank(confirmPassword)
								? <div className="text-error">[新しいパスワード再入力]を入力してください。</div>
								: <span></span>
						}
						{
							validating && !isBlank(confirmPassword) && !validatePassword(confirmPassword) && canChangePassword
								? <div className="text-error">パスワードは8文字以上に、は英大文字、小文字、数字、記号を各1文字以上使用してください。</div>
								: <span></span>
						}
						{
							validating && canChangePassword && !isBlank(newPassword) && !isBlank(confirmPassword) && newPassword !== confirmPassword
								? <div className="text-error">新しいパスワードと新しいパスワード再入力が一致しません。</div>
								: <span></span>
						}
					</div>
				</fieldset>
			</form>
			<div className="accoutInfo-button d-flex d-flex justify-content-center">
				<button
					type="button"
					className="btn btn-primary m-3"
					onClick={handleSubmit}>
					保存
				</button>
				<button
					type="button"
					className="btn btn-secondary m-3"
					onClick={() => props.onNavi('/')}> キャンセル</button >
			</div>
		</div>
	);
}