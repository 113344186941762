import React, { useEffect, useRef, useState } from 'react';
import './AlertMap.css';
import sky00087 from "../../assets/sound/sky00087.wav";
import sky00086 from "../../assets/sound/sky00086.wav";
import sky00077 from "../../assets/sound/sky00077.wav";
interface AlertMapProps {
  dataAlert: any;
}
const audioMapping = {
  P1: sky00077, 
  P2: sky00086, 
  P3: sky00086, 
  P4: sky00087, 
};
const AlertMap: React.FC<AlertMapProps> = ({
  dataAlert,
}) => {
  const audioQueue = useRef<any>([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const getPriority = (audioSrc: string) => {
    switch (audioSrc) {
      case audioMapping.P4:
        return 4; // Highest priority
      case audioMapping.P2:
      case audioMapping.P3:
        return 3; // Medium priority
      case audioMapping.P1:
        return 1; // Lowest priority
      default:
        return 0; // No priority
    }
  };

  const enqueueAudio = (audioSrc: string) => {
    const existingAudioIndex = audioQueue.current.findIndex(
      (src: string) => src === audioSrc
    );
    if (existingAudioIndex === -1) {
      audioQueue.current.push(audioSrc);
      // console.log(`Added to queue: ${audioSrc}`);
    } else {
      console.log(`Audio already in queue: ${audioSrc}`);
    }

    // Sort the queue in order of priority
    audioQueue.current.sort(
      (a: any, b: any) => getPriority(b) - getPriority(a)
    );
    // console.log("Current audio queue:", audioQueue.current);
  };  
  useEffect(() => {
    const requestUserMedia = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
      } catch (error) {
        // console.error("error", error);
      }
    };
    requestUserMedia();
  }, []);

  useEffect(() => {
    let audioSrc = null;

    if (dataAlert?.overTimeCount) {
      audioSrc = audioMapping.P4;
    } else if (dataAlert?.warningTimeCount || dataAlert?.overCastingCount) {
      audioSrc = audioMapping.P2;
    } else if (dataAlert?.warningCastingCount) {
      audioSrc = audioMapping.P1;
    }

    if (audioSrc) {
      if (!isPlaying) {
        enqueueAudio(audioSrc);
        playNextAudio();
      } else {
        enqueueAudio(audioSrc);
      }
    }
  }, [dataAlert]);

  const playNextAudio = () => {
    if (audioQueue.current.length === 0) return; 

    const audioSrc = audioQueue.current.shift(); 
    const audio = new Audio(audioSrc);

    setIsPlaying(true); 

    audio.play().catch((error) => {
      console.error("Playback prevented", error);
    });

    audio.onended = () => {
      setIsPlaying(false); 
      playNextAudio();
    };
  };

  return (
    <>
      <div className="independent-operation-container">
        <div className="title">運行自主警戒時間超過台数</div>
        <div className="col-12">
          <div className="row">
            <div className="col-3 pt-1 text-center bg-warning">警告</div>
            <div className="col-3 text-left p-1 bg-white">
              {dataAlert?.warningCastingCount || 0}
            </div>
            <div className="col-3 pt-1 text-center bg-danger">超過</div>
            <div className="col-3 text-left p-1 bg-white">
              {dataAlert?.overCastingCount || 0}
            </div>
          </div>
        </div>
      </div>
      <div className="placement-limit-container">
        <div className="title">打設制限時間超過台数</div>
        <div className="col-12">
          <div className="row">
            <div className="col-3 pt-1 text-center bg-warning">警告</div>
            <div className="col-3 text-left p-1 bg-white">
              {dataAlert?.warningTimeCount || 0}
            </div>
            <div className="col-3 pt-1 text-center bg-danger">超過</div>
            <div className="col-3 text-left p-1 bg-white">
              {dataAlert?.overTimeCount || 0}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertMap;