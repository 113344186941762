
import React from "react";
import "../Settings/Setting.css"
import "./EditTerminalMultiItem.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import { isBlank, toastOptions, getValidNumber } from "../../common/Utils";
import { toast } from "react-toastify";
import moment from "moment";


export default class EditTerminalMultiItem extends React.Component<
    {
        onNavi: Function,
    },
    {
        listData: any,
        listDataUpdate: any,
    }> {
    private inputImport: React.RefObject<HTMLInputElement>;
    constructor(props: any) {
        super(props)
        this.inputImport = React.createRef();

        this.state = {
            listData: [],
            listDataUpdate: [],
        }

        this.onFetchData = this.onFetchData.bind(this);
        this.onImport = this.onImport.bind(this);
        this.onExport = this.onExport.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onInputChange = this.onInputChange.bind(this);

    };

    userData = Cookies.get('user');
    user = JSON.parse(this.userData ? this.userData : '{}');

    componentDidMount() {
        this.onFetchData();
    }

    private onFetchData() {
        var api = "/Form/V1/Mst/Terminal/GetList?sysID=" + this.user.siteId
        axiosInstance
            .get(HOST() + api)
            .then(response => {
                const terminals = response.data.data;
                if (terminals && terminals.length > 0) {
                    this.setState({ listData: terminals })
                }
            })
    }

    private goBack(refresh: boolean) {
        if (refresh) this.props.onNavi('/', 'terminalList');
        else this.props.onNavi('/');
    }

    public onInputChange(event: any, index: number) {

        var inputName = event.target.name;
        var value = event.target.value;
        var newListData = [...this.state.listData]
        var newListDataUpdate = [...this.state.listDataUpdate]

        if (inputName === "cliNm") {
            newListData[index].cliNm = value;
            newListData[index].errorCliNm = '';
            if (isBlank(value)) {
                newListData[index].errorCliNm = "端末名が空です。有効な端末名を入力してください"
            }
            if (value.indexOf(',') !== -1) {
                newListData[index].errorCliNm = "端末名に「,」が含まれています。「,」は使用不可文字です"
            }
        }
        else if (inputName === "defSpdLim") {
            newListData[index].defSpdLim = getValidNumber(event, { minValue: 0, maxValue: 200, roundTo: 0 });
            newListData[index].errorDefSpdLim = '';
            if (newListData[index].defSpdLim < 0) {
                newListData[index].errorDefSpdLim = "制限速度が無効です。有効な制限速度を入力してください"
            }
        }
        else if (inputName === "telNo") {
            let financialGoal = (event.target.validity.valid) ? event.target.value : newListData[index].telNo;
            newListData[index].telNo = financialGoal;
        }
        else if (inputName === "driverNm") {
            newListData[index].driverNm = value;
            newListData[index].errorDriverNm = '';
            if (value.indexOf(',') !== -1) {
                newListData[index].errorDriverNm = "所有者に「,」が含まれています。「,」は使用不可文字です"
            }
        }
        else if (inputName === "belongTo") {
            newListData[index].belongTo = value;
        }
        else if (inputName === "fuelCons") {
            newListData[index].fuelCons = getValidNumber(event, { minValue: 0, maxValue: 999.99 });
            newListData[index].errorFuelCons = '';
            if (newListData[index].fuelCons.fuelCons < 0 || newListData[index].fuelCons.fuelCons > 999.99) {
                newListData[index].errorFuelCons = "燃料消費量が無効です。 有効な値を入れてください";
            }
        }
        else if (inputName === "fuelType") {
            newListData[index].fuelType = parseFloat(value);
            newListData[index].errorFuelType = '';
            if (isBlank(newListData[index].fuelType)) {
                newListData[index].errorFuelType = "燃料の種類が空です。 有効なタイプを入力してください";
            }
        }
        else if (inputName === "volume") {
            newListData[index].volume = parseFloat(value);
        }

        newListData[index].isEdit = true;
        var iUpdate = newListDataUpdate.findIndex(x => x.cliID == newListData[index].cliID);
        if (iUpdate > -1) {
            newListDataUpdate[iUpdate] = newListData[index];
        } else {
            newListDataUpdate.push(newListData[index])
        }

        this.setState({ listData: newListData, listDataUpdate: newListDataUpdate });
    }

    public async onImport(e: any) {
        const importFile = e.target.files ? e.target.files[0] : null;

        if (importFile) {
            const formData = new FormData();

            formData.append('siteId', this.user.siteId);
            formData.append('file', importFile, importFile.name);
            var api = "/Form/V1/Mst/Terminal/Import";
            axiosInstance
                .put(HOST() + api, formData, { headers: { "Content-Type": "multipart/form-data" } })
                .then(res => {
                    if (res.data.result) {
                        this.onFetchData();
                        toast.success('インポートしました。', toastOptions);
                    } else {
                        toast.error(res.data.message, toastOptions);
                    }
                })
        }
        if (this.inputImport.current) {
            this.inputImport.current.value = "";
        }
    };

    public onExport() {
        var api = "/Form/V1/Mst/Terminal/Download?siteId=" + this.user.siteId
        axiosInstance
            .get(HOST() + api, { responseType: 'blob' })
            .then(res => {
                const fileName = `${this.user.siteId}_${moment().format('YYYYMMDD')}`;
                const file = window.URL.createObjectURL(res.data as any);
                const aTag = document.createElement('a');
                aTag.href = file;
                aTag.download = fileName;
                aTag.click();
                toast.success('エクスポートに成功しました', toastOptions);
            })
    }

    public onCancel() {
        this.goBack(false);
    }

    public onSave() {
        if (this.state.listDataUpdate.length > 0) {
            var api = "/Form/V1/Mst/Terminal/UpdateList";
            axiosInstance
                .put(HOST() + api, { "terminals": this.state.listDataUpdate })
                .then(res => {
                    if (res.data.result) {
                        toast.success('更新に成功。', toastOptions);
                    } else {
                        toast.error(res.data.message, toastOptions);
                    }
                })
        }

    }

    public render() {
        return (
            <div>
                <div className="box edit-terminal-multi">
                    <div className="box-title d-flex justify-content-between align-items-center mb-4">
                        <span className="page-header">端末登録</span>
                        <div className="buttonEdit">
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.onExport} >Excel出力</button>
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.inputImport.current?.click()} >Excel取込</button>
                            <input ref={this.inputImport} hidden type='file' onChange={this.onImport} />
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.onCancel} >キャンセル</button>
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.onSave} >保存</button>
                        </div>
                    </div>
                    <div className="row pb-3">
                        <table style={{ marginBottom: "10px" }}>
                            <tbody>
                                <tr>
                                    <th>端末ID</th>
                                    <th>端末名</th>
                                    <th>制限速度</th>
                                    <th>電話番号</th>
                                    <th>所有者</th>
                                    <th>所属</th>
                                    <th>燃費(km/L)</th>
                                    <th>積載量(m3)</th>
                                </tr>
                                {this.state.listData.map((item: any, index: number) => (
                                    <tr>
                                        <td>
                                            {item.cliID}
                                        </td>
                                        <td>
                                            <input
                                                name="cliNm"
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={item.cliNm}
                                                onChange={e => this.onInputChange(e, index)} style={{ width: "100%" }} maxLength={20}
                                            />
                                            <span className="errClass">{item.errorCliNm}</span>
                                        </td>
                                        <td>
                                            <input
                                                name="defSpdLim"
                                                type="number"
                                                className="form-control form-control-sm"
                                                value={item.defSpdLim}
                                                onChange={e => this.onInputChange(e, index)} style={{ width: "100%" }}
                                                min={0} max={200}
                                            />
                                            <span className="errClass">{item.errorDefSpdLim}</span>
                                        </td>
                                        <td>
                                            <input
                                                name="telNo"
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={item.telNo}
                                                onChange={e => this.onInputChange(e, index)} style={{ width: "100%" }} maxLength={20}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name="driverNm"
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={item.driverNm}
                                                onChange={e => this.onInputChange(e, index)} style={{ width: "100%" }} maxLength={20}
                                            />
                                            <span className="errClass">{item.errorDriverNm}</span>
                                        </td>
                                        <td>
                                            <input
                                                name="belongTo"
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={item.belongTo}
                                                onChange={e => this.onInputChange(e, index)} style={{ width: "100%" }} maxLength={20}
                                            />
                                        </td>
                                        <td>
                                            <div className="d-flex">
                                                <input
                                                    name="fuelCons"
                                                    type="number"
                                                    className="form-control form-control-sm col-6 mr-1"
                                                    value={item.fuelCons}
                                                    onChange={e => this.onInputChange(e, index)} style={{ width: "100%" }} maxLength={6}
                                                />
                                                <select
                                                    className="form-control form-control-sm col-6"
                                                    name="guelType"
                                                    onChange={e => this.onInputChange(e, index)}
                                                    value={item.fuelType}
                                                >
                                                    <option value="2.32">ガソリン</option>
                                                    <option value="2.58">軽油</option>
                                                </select>
                                            </div>
                                            <span className="errClass">{item.errorFuelCons || item.errorFuelType}</span>
                                        </td>
                                        <td>
                                            <input
                                                name="volume"
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={item.volume}
                                                onChange={e => this.onInputChange(e, index)} style={{ width: "100%" }} maxLength={20}
                                            />
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    };
}