import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout/Layout';
import Home from './components/Home';
import './custom.css'
import Login from './components/Login/Login';
import Admin from './components/Admin/Admin';

declare global {
    interface Window {
        google: any;
        initMap: any;
    }
}

export default () => {
  return (
    <Layout>
      <Route exact path="/" component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/admin" component={Admin} />
    </Layout>
  );
};
